import axios from "axios";

export async function getQuestionDetail(params) {
    return axios.get('/question/detail', {
        params
    });
}

export async function getQuestionTree() {
    return axios.get('/question/getTree');
}