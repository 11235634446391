<template>
    <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <img :src="JSON.parse(eventData.detail).imageUrl" alt="活动图片" style="max-width: 33.3333%;"
                class="mb-6 rounded-lg shadow-md" v-if="eventData">
            <p class="text-gray-700 mb-8 text-xl" v-if="eventData">{{ eventData.name }}</p>
        </div>
        <!-- 使用v-if检查canJoin的值来决定按钮是否显示 -->
        <div v-if="errorMessage" class="error-message p-2 my-4 bg-red-100 rounded-lg text-red-800">
            <p>{{ errorMessage }}</p>
            <button @click="clearErrorMessage" class="ml-2 px-2 py-1 rounded-md text-red-600 hover:text-red-700">
                关闭
            </button>
        </div>

        <!-- 新增成功信息提示 -->
        <div v-if="successMessage" class="success-message p-2 my-4 bg-green-100 rounded-lg text-green-800">
            <p>{{ successMessage }}</p>
            <button @click="clearSuccessMessage" class="ml-2 px-2 py-1 rounded-md text-green-600 hover:text-green-700">
                关闭
            </button>
        </div>

        <template v-if="order">
            <p class="text-green-600 text-center text-lg font-medium">已参加活动 {{ order.detail }}</p>
        </template>
        <button v-if="canJoin" @click="join"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            参加
        </button>
        <p v-if="!eventData && !canJoin" class="text-center text-gray-500 text-lg font-semibold mt-12">暂无活动</p>
    </div>
</template>

<script>
import { getActivityOrder, getRecentActivity, getRightActivity, killActivity } from '@/openapi/activity';

export default {
    data() {
        return {
            eventData: null,
            canJoin: false, // 初始值设为false
            errorMessage: '',
            order: null
        };
    },
    methods: {
        async fetchEventData() {
            try {
                const response = await getRecentActivity();
                if (response.status === 200 && response.data.data) {
                    this.eventData = response.data.data;
                    this.fetchData() // 在组件创建后立即获取数据
                    this.getOrder()
                } else {
                    console.error('Error fetching event data:', response.statusText);
                }
            } catch (error) {
                console.error('Failed to fetch event data:', error.message);
            }
        },
        async fetchData() {
            try {
                if (!this.eventData) return
                const response = await getRightActivity({
                    activityId: this.eventData.id
                });
                // 假设response.data中有一个字段，比如isJoinable，用于判断是否可以参加
                this.canJoin = response.data.data;
            } catch (error) {
                console.error('Error:', error);
            }
        },
        async join() {
            try {
                const response = await killActivity({
                    activityId: this.eventData.id
                });
                if (response.data.code === 200) {
                    this.fetchData()
                    this.getOrder()
                    return
                }
                this.errorMessage = response.data.message
            } catch (error) {
                console.error("添加到购物车失败:", error);
            }
        },
        async getOrder() {

            // 如果需要，你可以在这里触发API调用来更新服务器端的购物车状态
            try {
                const response = await getActivityOrder({
                    activityId: this.eventData.id
                });
                if (response.data.code === 200) {
                    this.order = response.data.data
                    return
                }
                this.errorMessage = response.data.message
            } catch (error) {
                console.error("添加到购物车失败:", error);
            }
        },
        clearErrorMessage() {
            this.errorMessage = '';
        }
    },
    created() {
        this.fetchEventData()
    },
}
</script>