<template>
    <li>
        <div class="node" :style="{ paddingLeft: `${node.level * 16}px` }" @click.stop="selectNode">
            <span v-if="!node.leaf" class="fold-icon" @click.stop="toggleExpand">
                <img :src="expandIcon"
                    class="transform transition-transform duration-300 fold-icon cursor-pointer mr-0 w-4 h-4"
                    :class="{ 'rotate-90': isExpanded }" />
            </span>
            <span v-if="node.leaf" class="fold-placeholder"></span>
            <p class="truncate max-w-[200px]">
                {{ node.title }}
            </p>
        </div>
    </li>
</template>

<script>
import expandIcon from "@/assets/expand.svg";
export default {
    props: {
        node: {
            type: Object,
            required: true,
        },
        expandedSet: {
            type: Set,
            required: true,
        }
    },
    data() {
        return {
            expandIcon,
        };
    },
    computed: {
        isExpanded() {
            return this.expandedSet.has(this.node.id);
        }
    },
    emits: ['select', 'toggle-expand'],
    methods: {
        selectNode() {
            this.$emit('select', this.node);
        },
        toggleExpand() {
            this.$emit('toggle-expand', this.node);
        }
    },
};
</script>

<style scoped>
.node {
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 5px;
}

.fold-icon {
    cursor: pointer;
    margin-right: 0px;
    width: 1em;
}

.fold-placeholder {
    width: 1em;
    /* 隐藏占位符 */
    visibility: hidden;
}
</style>
