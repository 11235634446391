import axios from "axios";

export async function userLogin(body) {
    return axios.post('/user/login', body);
}

export async function hadModifyPwd() {
    return axios.get('/user/hadModifyPwd');
}

export async function getUserDetail() {
    return axios.get('/user/getDetail');
}

export async function resetAccount(body) {
    return axios.post('/user/resetAccount', body);
}

export async function saveUser(body) {
    return axios.post('/user/save', body);
}

export async function updatePassword(body) {
    return axios.post('/user/updatePassword', body);
}

export async function userCheckIn() {
    return axios.post('/user/checkIn');
}

export async function getCheckIn() {
    return axios.get('/user/getCheckIn');
}

export async function getCurrUserRank() {
    return axios.get('/user/rank');
}

export async function getUserRankList() {
    return axios.get('/user/ranks');
}

export async function searchUser(params) {
    return axios.get('/user/searchUser', { params });
}