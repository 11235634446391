<template>
  <ul class="tree-view">
    <TreeNode v-for="(item, index) in flattenTree" :key="index" :node="item" :expandedSet="expandedSet"
      @select="onSelect" @toggle-expand="onToggleExpand" />
  </ul>
</template>

<script>
import TreeNode from '@/components/TreeNode.vue';
import { ref } from 'vue';

export default {
  components: {
    TreeNode,
  },
  props: {
    treeData: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      default: "id"
    },
    labelField: {
      type: String,
      default: "title"
    },
    childrenField: {
      type: String,
      default: "subTreeNodeResp"
    },
    defaultExpandKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expandedSet: ref(new Set(this.defaultExpandKeys))
    };
  },
  computed: {
    flattenTree() {
      const res = [];
      const dfs = (tree) => {
        tree.forEach((node) => {
          res.push(node);
          if (this.expandedSet.has(node.id)) {
            dfs(node.children);
          }
        });
      };
      dfs(this.formatTreeData(this.treeData));
      return res;
    },
  },
  methods: {
    onSelect(node) {
      this.$emit('selectNode', node);
    },
    formatTreeData(data, parent) {
      return data.map((item) => {
        if (Object.keys(item).length === 0 && item.constructor === Object) {
          return null;
        }

        const children = item[this.childrenField] || [];
        const treeNode = {
          id: item[this.keyField],
          title: item[this.labelField],
          children: [],
          level: parent ? parent.level + 1 : 0,
          parentKey: parent ? parent.key : null,
          leaf: item.isLeaf !== void 0 ? item.isLeaf : children.length === 0,
        };

        if (children.length) {
          treeNode.children = this.formatTreeData(children, treeNode);
        }
        return treeNode;
      }).filter(item => item !== null);
    },
    onToggleExpand(node) {
      if (this.expandedSet.has(node.id)) {
        this.expandedSet.delete(node.id);
      } else {
        this.expandedSet.add(node.id);
      }
    }
  },
};
</script>
