<template>
    <div>
        <div v-if="inImageUploading" class="loading-overlay">
            <div class="loading-spinner"></div>
            <p>图片上传中...</p>
        </div>
        <div>
            <MdEditor :toolbars="toolbars" v-model="localMdText" @onUploadImg="doUploadImage" class="text-left">
                <template #defToolbars>
                    <Mark></Mark>
                    <Emoji />
                    <ModalToolbar title="BiliBili">
                        <template #trigger>
                            <div @click="showBiliVideoModal = true">
                                <img src="../assets/bilibili.png" alt="bilibili" class="w-6 h-6" />
                            </div>
                        </template>
                    </ModalToolbar>
                    <ModalToolbar title="PublishVideo" v-if="communityCreatorFeatures">
                        <template #trigger>
                            <div @click="showBitfreeVideoModal = true">
                                <img src="../assets/bitfree_video_icon.png" alt="bitfree" class="w-5 h-5" />
                            </div>
                        </template>
                    </ModalToolbar>
                </template>
            </MdEditor>

            <!-- 插入B站视频 -->
            <div v-if="showBiliVideoModal" class="modal-overlay">
                <div class="modal-content">
                    <h2 class="modal-title">插入Bilibili视频</h2>
                    <img src="../assets/video_url_guide.png" alt="guide" class="guide-image" />
                    <textarea v-model="bilibiliVideoUrl" placeholder="输入Bilibili视频地址" class="modal-input"></textarea>
                    <div class="modal-actions">
                        <button @click="showBiliVideoModal = false" class="modal-button modal-cancel">取消</button>
                        <button @click="insertBiliBiliVideo" class="modal-button modal-insert">插入视频</button>
                    </div>
                </div>
                <div class="modal-backdrop" @click="showBiliVideoModal = false"></div>
            </div>

            <!-- 海哥专属上传视频通道 -->
            <template v-if="communityCreatorFeatures">
                <div v-if="showBitfreeVideoModal" class="modal-overlay">
                    <div class="modal-content">
                        <h2 class="modal-title">插入视频</h2>
                        <textarea v-model="bitfreePosterUrl" placeholder="输入视频封面" class="modal-input"></textarea>
                        <textarea v-model="bitfreeVideoUrl" placeholder="输入视频地址" class="modal-input"></textarea>
                        <div class="modal-actions">
                            <button @click="showBitfreeVideoModal = false" class="modal-button modal-cancel">取消</button>
                            <button @click="insertBitfreeVideo($event)" class="modal-button modal-insert">插入视频</button>
                        </div>
                    </div>
                    <div class="modal-backdrop" @click="showBitfreeVideoModal = false"></div>
                </div>
            </template>

        </div>
    </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted } from 'vue';
import { MdEditor, ModalToolbar } from 'md-editor-v3';
import { Emoji, Mark } from '@vavt/v3-extension';
import { debounce } from 'lodash';
import 'md-editor-v3/lib/style.css';
import '@vavt/v3-extension/lib/asset/style.css';
import { imageSource } from '@/common/common';
import { uploadImage } from '@/openapi/image';
import { uploadFile } from '@/openapi/file';

const toolbars = [
    'bold',
    'underline',
    'italic',
    'strikeThrough',
    '-',
    'title',
    'sub',
    'sup',
    'quote',
    'unorderedList',
    'orderedList',
    'task',
    '-',
    'codeRow',
    'code',
    'link',
    'image',
    'table',
    'mermaid',
    'katex',
    0,
    1,
    2,
    3,
    '-',
    'revoke',
    'next',
    'save',
    '=',
    'prettier',
    'pageFullscreen',
    'fullscreen',
    'preview',
    'previewOnly',
    'catalog',
];

const communityCreatorFeatures = ref(false);
onMounted(() => {
    const currentUserLevel = JSON.parse(localStorage.getItem("user"))?.level || 0;
    communityCreatorFeatures.value = currentUserLevel === 3;
})

const props = defineProps({
    mdText: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:mdText']);

const localMdText = ref(props.mdText);
const debouncedEmit = debounce((newVal) => {
    emit('update:mdText', newVal);
}, 300);
watch(localMdText, (newVal) => {
    debouncedEmit(newVal);
});

const insertMdText = (value) => {
    localMdText.value += `${value}`;
}

const showBiliVideoModal = ref(false);
const bilibiliVideoUrl = ref("");
const insertBiliBiliVideo = () => {
    /**
     * 辅助函数
     */
    const parseBiliBiliVideoUrl = (originUrl) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(originUrl, 'text/html');
        const iframe = doc.querySelector('iframe');

        if (iframe) {
            let src = iframe.getAttribute('src');
            if (src) {
                // 在 src 的末尾拼接 &autoplay=0, 禁止自动播放
                src += '&autoplay=0';
                iframe.setAttribute('src', src);
            }
            iframe.setAttribute('width', '100%');
            iframe.setAttribute('height', '500');
            iframe.setAttribute('sandbox', 'allow-top-navigation allow-same-origin allow-forms allow-scripts');
            iframe.setAttribute('allowfullscreen', 'allowfullscreen');
            iframe.removeAttribute('border');
            iframe.removeAttribute('framespacing');
        }

        return iframe.outerHTML;
    };
    if (bilibiliVideoUrl.value) {
        const output = parseBiliBiliVideoUrl(bilibiliVideoUrl.value);
        insertMdText(`\n${output}\n`);
        bilibiliVideoUrl.value = "";
        showBiliVideoModal.value = false;
    }
};

const showBitfreeVideoModal = ref(false);
const bitfreeVideoUrl = ref("");
const bitfreePosterUrl = ref("");
const insertBitfreeVideo = async (event) => {
    event.preventDefault();
    const doUpload = async () => {
        const res = await uploadFile({
            poster: bitfreePosterUrl.value,
            fileUrl: bitfreeVideoUrl.value
        });
        return res.data.data.id;
    }

    const videoId = await doUpload();
    insertMdText(`[fileId:${videoId}]`);

    bitfreeVideoUrl.value = "";
    bitfreePosterUrl.value = "";
    showBitfreeVideoModal.value = false;
}

const inImageUploading = ref(false);
const doUploadImage = async (files, callback) => {
    inImageUploading.value = true;
    try {
        const res = await Promise.all(
            files.map((file) => {
                return new Promise((rev, rej) => {
                    uploadImage(file, imageSource.POST)
                        .then((res) => rev(res))
                        .catch((error) => rej(error));
                });
            })
        );
        callback(res.map((item) => {
            return item.data.data;
        }));
    } catch (error) {
        console.error('Image upload failed:', error);
    } finally {
        inImageUploading.value = false;
    }
};
</script>

<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    flex-direction: column;
}

.loading-spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.modal-content {
    background-color: white;
    padding: 1.5rem;
    border-radius: 6px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 24rem;
    max-width: 90%;
    z-index: 60;
    box-sizing: border-box;
}

.modal-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.modal-input {
    border: 1px solid #d1d5db;
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 1rem;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
}

.modal-button {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-left: 0.5rem;
}

.modal-cancel {
    background-color: #e5e7eb;
    color: black;
}

.modal-insert {
    background-color: #3b82f6;
    color: white;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 55;
}

.guide-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
}
</style>