<template>
    <div class="flex h-screen overflow-y-auto" @scroll="onScroll">
        <div class="flex-shrink-0 w-1/5 bg-gray-100 overflow-y-auto" v-bind:class="{ hidden: !isOnlineUsersOpen }">
            <!-- 左侧联系人列表 -->
            <div class="px-4 py-2 space-y-4">
                <transition name="fade">
                    <div class="px-4 py-2 space-y-4">
                        <h3 class="text-lg font-medium">在线用户({{ onlineUsers.length }}人)</h3>
                        <div v-for="user in onlineUsers" :key="user.id"
                            class="flex items-center px-4 py-2 cursor-pointer">
                            <img :src="user.avatar" alt="Avatar" class="w-8 h-8 rounded-full object-cover mr-3">
                            <div class="flex flex-col justify-between">
                                <!-- 使用内联样式控制文本溢出 -->
                                <span class="text-gray-900 block truncate max-w-full" :title="user.name">
                                    {{ user.name }}
                                </span>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
        <div class="flex-1 md:w-3/5 mt-5">
            <a href="#" @click="isOnlineUsersOpen = !isOnlineUsersOpen" class="text-gray-700 hover:text-gray-900 m-4">
                <span v-if="!isOnlineUsersOpen">显示全部信息</span>
                <span v-if="isOnlineUsersOpen">全屏</span>
            </a>
            <!-- 聊天窗口 -->
            <div class="relative h-full">
                <form @submit="sendMessage" class="flex items-center p-4 space-x-3">
                    <input v-model="inputMessage"
                        class="w-full bg-gray-200 border border-gray-300 text-gray-900 rounded-lg py-2 px-3"
                        placeholder="输入消息..." @input="handleInput">
                    <!-- 在输入框下方添加提及建议列表 -->
                    <ul v-if="mentionSuggestions && mentionSuggestions.length > 0" class="mention-suggestions">
                        <li v-for="(suggestion, index) in mentionSuggestions" :key="suggestion.id"
                            @click="selectMention(suggestion)">
                            {{ suggestion.name }}
                        </li>
                    </ul>
                    <button type="submit" class="bg-gray-500 text-white hover:bg-gray-600 rounded-lg py-2 px-3">
                        发送
                    </button>
                </form>

                <div>
                    <!-- 添加表情按钮 -->
                    <div class="flex justify-between items-center p-4 mt-0 pt-0">
                        <button @click="showEmojiPicker = true" class="px-4 py-2 bg-blue-200 text-white rounded-md">
                            选择表情
                        </button>
                        <!-- 在输入框旁边添加上传图片按钮 -->
                        <div class="flex items-center">
                            <label for="image-upload"
                                class="bg-gray-300 rounded px-4 py-2 text-base font-medium cursor-pointer">
                                上传图片
                            </label>
                            <div v-if="isUploading" class="fixed inset-0 z-10 overflow-y-auto">
                                <div
                                    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                                    </div>

                                    <!-- 弹窗本体 -->
                                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
                                        aria-hidden="true">&#8203;</span>
                                    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                                        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                            <div class="sm:flex sm:items-start">
                                                <div
                                                    class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                                    <i class="fas fa-spinner fa-pulse text-blue-600"></i>
                                                </div>
                                                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                    <h3 class="text-lg leading-6 font-medium text-gray-900"
                                                        id="modal-headline">
                                                        上传中...
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input id="image-upload" type="file" accept="image/*" @change="onImageSelect"
                                class="hidden">
                        </div>
                    </div>

                    <!-- 表情选择弹窗 -->
                    <div v-if="showEmojiPicker" class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="min-h-screen px-4 text-center">
                            <div class="fixed inset-0 transition-opacity" @click="showEmojiPicker = false"></div>

                            <div
                                class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-xl p-6 dialog-modal max-h-screen overflow-y-auto">

                                <div class="flex justify-end">
                                    <button @click="showEmojiPicker = false" type="button"
                                        class="text-gray-400 hover:text-gray-900 focus:outline-none">
                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div class="flex flex-wrap mt-4">
                                    <span v-for="emoji in emojis" :key="emoji" @click="addEmoji(emoji)">
                                        <img :src="'static/emoji/' + emoji + '.webp'"
                                            class="w-10 object-contain mr-2 cursor-pointer" alt="emoji" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="p-5">
                    <!-- 聊天记录 -->
                    <div ref="chatContainer" class="overflow-y-auto chat-container">
                        <div v-for="(message, index) in messages" :key="message.id"
                            class="relative flex items-center space-x-3 mb-4" :class="getJustify(message)">

                            <div v-if="message.userId === user.id" class="relative flex items-center space-x-3 mb-4">
                                <div class="rounded-lg bg-blue-200 p-3 shadow-md flex-col justify-start items-start space-y-2"
                                    :class="getBubbleClass(message)" @mouseover="onMouseOver(index)">
                                    <div class="flex justify-end">
                                        <div class="max-w-[content] overflow-hidden whitespace-pre-wrap">
                                            <p class="text-gray-900 text-1xl font-bold break-all text-right"
                                                v-html="replaceEmojiWithImages(message.content)"></p>
                                            <p class="text-gray-600 text-xs italic text-right">{{
                                                formatTime(message.createTime) }}</p>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    class="abolute bottom-2 left-2 text-gray-500 hover:text-gray-700 transition-colors duration-200 text-right"
                                    @click="retractMessage(index)" v-if="message.showRetractButton">
                                    撤回
                                </button>

                                <div class="flex flex-col items-center">
                                    <!-- 新增容器并使用Flex布局 -->
                                    <div class="relative w-12 h-12 rounded-full bg-gray-200 flex-shrink-0">
                                        <img :src="message.avatar" alt="Avatar"
                                            class="w-full h-full rounded-full object-cover" />
                                    </div>
                                    <span :class="message.userId === 701 ? 'text-pink-500' : 'text-gray-700'"
                                        class="font-medium text-center text-sm mt-1"> <!-- 添加mt-1为用户名添加一点上边距 -->
                                        {{ message.userName }}
                                    </span>
                                </div>
                            </div>

                            <div v-if="message.userId !== user.id" class="relative flex items-center space-x-3 mb-4">
                                <div class="flex flex-col items-center">
                                    <div class="relative w-12 h-12 rounded-full bg-gray-200 flex-shrink-0">
                                        <img :src="message.avatar" alt="Avatar"
                                            class="w-full h-full rounded-full object-cover" />
                                    </div>
                                    <span :class="message.userId === 701 ? 'text-pink-500' : 'text-gray-700'"
                                        class="font-medium text-center text-sm mt-1"> <!-- 添加mt-1为用户名添加一点上边距 -->
                                        {{ message.userName }}
                                    </span>
                                </div>
                                <div class="rounded-lg p-3 border-2 shadow-md" :class="getBubbleClass(message)">
                                    <!-- <a  target="_blank" href="https://img.picui.cn/free/2024/06/21/6674f64aeafcd.png"> <img src="https://img.picui.cn/free/2024/06/21/6674f64aeafcd.png" /></a> -->
                                    <p :class="message.mentionedUserId === user.id ? 'text-red-500' : 'text-gray-900'"
                                        class="text-gray-900 text-1xl font-bold break-all text-left"
                                        v-html="replaceEmojiWithImages(message.content)"></p>
                                    <!-- 时间戳 -->
                                    <p class="text-gray-600 text-xs text-left italic">{{ formatTime(message.createTime)
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button @click="loadMoreMessages">
                    <div class="flex justify-center items-center w-full mt-4">
                        <p class="text-gray-600 dark:text-gray-400">下滑或点击加载更多</p>
                    </div>
                </button>
            </div>
        </div>

        <div class="flex-shrink-0 w-1/5 bg-gray-100 overflow-y-auto" v-bind:class="{ hidden: !isOnlineUsersOpen }">
            <div class="px-4 py-2 space-y-4">
                <transition name="fade">
                    <div>
                        <h3 class="text-lg font-medium">硬币排行榜</h3>
                        <h4>我的排名：{{ myRankPostion }}</h4>
                        <div v-if="this.rankUserList.length > 0">
                            <div v-for="(user, index) in rankUserList" :key="user.id || index">
                                <div class="flex justify-between items-center mt-5">
                                    <img :src="user.avatar" alt="Avatar" class="w-10 h-10 rounded-full">
                                    <div>
                                        <p class="font-bold">{{ user.name }}</p>
                                    </div>
                                    <div>
                                        <p>{{ user.coins }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p v-else>加载中...</p>
                    </div>
                </transition>
            </div>
        </div>
    </div>

</template>

<script>
import { debounce } from "lodash";
import { emojis, formatTime, imageSource, replaceEmojiWithImages } from "@/common/common";
import { uploadImage } from "@/openapi/image";
import { deleteMessage, getLiveUserList, getRecentMessageList, msgHeartbeat, sendMessage } from "@/openapi/message";
import { getCurrUserRank, getUserRankList, searchUser } from "@/openapi/user";
export default {
    data() {
        return {
            onlineUsers: [],
            messages: [], // 从服务器获取的聊天记录
            inputMessage: '', // 输入框内容
            currentPage: 1,
            limit: 10, // 每页显示的消息数量,
            liveTimer: null,
            messageTimer: null,
            user: JSON.parse(localStorage.getItem('user')) || null,
            showEmojiPicker: false,
            isOnlineUsersOpen: this.isPcDevice(),
            rankUserList: [],
            heartbeatTimer: null,
            previewImageUrl: '',
            emojis,
            isLoadingMore: false,
            atUser: null,
            mentionSearchTerm: '', // 用于存储提及关键词
            mentionSuggestions: [], // 存储提及建议的数组
            isUploading: false, // 用于控制加载指示器的显示与隐藏
            myRankPostion: 0,
            showRetractButton: false
        };
    },
    mounted() {
        this.fetchMessages(this.currentPage);
        this.messageTimer = setInterval(this.fetchMessages, 3000);

        this.fetchOnlineUsers();
        this.liveTimer = setInterval(this.fetchOnlineUsers, 3000); // 每3秒更新一次

        this.heartbeat();
        this.heartbeatTimer = setInterval(this.heartbeat, 60000);

        this.$nextTick(() => {
            const previewableImages = document.querySelectorAll('.previewable');
            previewableImages.forEach(img => {
                img.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.openModal(img.src);
                });
            });
        });
    },
    computed: {
        userList() {
            return this.isOnlineUsersOpen ? this.onlineUsers : [];
        },
    },
    methods: {
        onImageSelect(event) {
            const file = event.target.files[0];
            if (!file) {
                alert('未选择文件');
                return;
            }

            if (!file.type.startsWith('image/')) {
                alert('请选择一个图片文件');
                return;
            }

            this.isUploading = true;
            uploadImage(file, imageSource.CHAT)
                .then(response => {
                    if (response.status != 200) {
                        alert("图片上传失败")
                        return
                    }
                    this.inputMessage = `<img src="${response.data.data}" class="inline-block" />`;
                    this.sendMessage()
                })
                .catch(error => {
                    console.error('图片上传失败', error);
                })
                .finally(() => {
                    this.isUploading = false;
                });
        },
        onMouseOver(index) {
            this.messages[index].showRetractButton = true;
        },
        sendMessage(e) {
            // 图片可以直接发送
            if (e != null) e.preventDefault();
            // 发送消息到服务器并清空输入框
            if (this.inputMessage.trim()) {
                sendMessage(
                    { content: this.inputMessage, replyMessageId: null, atUser: this.atUser },
                    JSON.parse(localStorage.getItem("user"))?.id + '-' + this.inputMessage
                )
                    .then(() => {
                        this.mentionSuggestions = [];
                        this.inputMessage = "";
                        // 可以考虑在这里触发fetchMessages，以立即显示新发送的消息
                        this.fetchMessages(this.currentPage);
                    })
                    .catch((error) => {
                        console.error("Error sending message:", error);
                    });
            } else {
                alert("请输入消息内容");
            }
        },
        retractMessage(index) {
            // 你可以在这里调用API发送撤回请求
            deleteMessage(null, { id: this.messages[index].id })
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.messages.splice(index, 1);
                        this.fetchMessages(this.currentPage)
                    } else {
                        alert(resp.data.message)
                    }
                })
        },
        toggleOnlineUsers() {
            this.isOnlineUsersOpen = !this.isOnlineUsersOpen;
        },
        fetchOnlineUsers() {
            if (!this.isOnlineUsersOpen) return
            getLiveUserList()
                .then((response) => {
                    this.onlineUsers = response.data.data;
                })
                .catch((error) => {
                    console.error("Error fetching online users:", error);
                });
        },
        selectMention(selectedSuggestion) {
            const atIndex = this.inputMessage.lastIndexOf('@') + 1;
            const beforeText = this.inputMessage.substring(0, atIndex);
            const afterText = this.inputMessage.substring(atIndex + this.mentionSearchTerm.length);

            // 构建最终的输入文本，将提及项插入到@之后
            const finalText = `${beforeText}${selectedSuggestion.name} ` + afterText;
            this.inputMessage = finalText;
            this.mentionSuggestions = []; // 选择后清空提及建议列表
            this.mentionSearchTerm = ''; // 重置提及关键词
            this.atUser = selectedSuggestion.id
        },
        heartbeat() {
            msgHeartbeat();
        },
        async fetchMessages(page = 1) {
            const response = await getRecentMessageList({ page: page, size: this.limit });
            const newMessages = response.data.data.list;
            this.messages = newMessages
            this.currentPage = page;
        },
        async loadMoreMessages() {
            if (this.isLoadingMore) {
                return;
            }
            this.isLoadingMore = true;

            try {
                this.limit = this.limit + 10;
                this.fetchMessages();
            } catch (error) {
                console.error("Error loading more messages:", error);
            } finally {
                this.isLoadingMore = false;
            }
        },
        stopFetchingMessages() {
            if (this.intervalId) {
                clearInterval(this.intervalId); // 清除定时器
                this.intervalId = null; // 重置定时器ID
            }
        },
        handleInput(event) {
            const inputValue = event.target.value;
            const atIndex = inputValue.lastIndexOf('@');

            if (atIndex !== -1 && atIndex === inputValue.length - 1) {
                this.mentionSearchTerm = ''; // 清空提及关键词，准备接收新的输入
            } else if (atIndex !== -1) {
                this.mentionSearchTerm = inputValue.substring(atIndex + 1).trim(); // 提取提及关键词
                if (this.mentionSearchTerm) {
                    this.fetchMentionSuggestions(this.mentionSearchTerm); // 发起请求获取提及建议
                }
            } else {
                this.mentionSuggestions = []; // 如果没有@，清空提及建议
            }
        },
        getRankPostion() {
            getCurrUserRank().then(resp => this.myRankPostion = resp.data.data)
        },
        fetchMentionSuggestions(term) {
            searchUser({ "name": term })
                .then(resp => this.mentionSuggestions = resp.data.data)
        },
        getJustify(message) {
            return message.userId === this.user.id ? 'justify-end' : 'justify-start'
        },
        removeTimer() {
            clearInterval(this.liveTimer)
            clearInterval(this.messageTimer)
            clearInterval(this.heartbeatTimer)
        },
        handleKeyDown(e) {
            if (e.key === 'Enter') {
                // 提交输入或执行其他操作
            }
        },
        addEmoji(emoji) {
            this.inputMessage += emoji;
            this.showEmojiPicker = false;
        },
        getBubbleClass(message) {
            return this.user && this.user.id === message.userId ? 'bg-green-200' : 'bg-white';
        },
        isPcDevice() {
            // 这里使用了一种常见但简化的判断方式，通过屏幕宽度来区分
            // 你可以根据实际情况调整阈值或采用其他设备检测库
            return window.innerWidth >= 768; // 假设768px为PC和移动的分界点
        },
        async loadRankUserList() {
            try {
                const response = await getUserRankList();
                this.rankUserList = response.data.data;
            } catch (error) {
                console.error('获取用户列表失败:', error);
            }
        },
        onScroll: debounce(function (e) {
            if ((e.target.scrollTop + e.target.clientHeight) >= e.target.scrollHeight) {
                this.loadMoreMessages();
            }
        }, 200),
        replaceEmojiWithImages,
        formatTime
    },
    beforeMount() {
        this.stopFetchingMessages(); // 组件销毁前确保清除定时器
    },
    beforeUnmount() {
        this.removeTimer()
    },
    created() {
        this.getRankPostion()
        this.loadRankUserList()
    }
};
</script>

<style>
.dialog-modal {
    width: calc(50% - 30px);
    height: calc((50% - 30px) * 2 / 3);
    margin: 15px;
    /* 增加15px的外边距，以抵消减去的30px */
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* ...保留原有的样式... */
.text-fit {
    font-size: clamp(1px, 10px, 18px);
    /* 设置字体大小的最小值、最大值和默认值 */
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏超出容器的文本 */
    text-overflow: ellipsis;
    /* 使用省略号表示被隐藏的文本 */
}

.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all .3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

.mention-suggestions {
    position: absolute;
    z-index: 100;
    list-style-type: none;
    padding: 0;
    margin-top: 2px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mention-suggestions li {
    padding: 5px 10px;
    cursor: pointer;
}

.mention-suggestions li:hover {
    background-color: #eee;
}
</style>