import axios from "axios";

export async function getRecentActivity() {
    return axios.get('/activity/getRecent');
}

export async function getRightActivity(params) {
    return axios.get('/activity/getRight', {
        params
    });
}

export async function killActivity(body) {
    return axios.post('/activity/kill', body);
}

export async function getActivityOrder(params) {
    return axios.get('/activity/getOrder', {
        params
    });
}