import axios from "axios";

export async function uploadFile(body) {
    return axios.post('/file/upload', body);
}

export async function getFileById(params) {
    return axios.get('/file/getById', {
        params
    });
}