import axios from "axios";

export async function getCollectList(params) {
    return axios.get('/post/getCollectList', {
        params
    });
}

export async function addPost(body) {
    return axios.post('/post/add', body);
}

export async function queryPostByPage(params) {
    return axios.get('/post/pageQuery', {
        params
    });
}

export async function getPostDetail(params) {
    return axios.get('/post/getDetail', {
        params
    });
}

export async function getPostReplyList(params) {
    return axios.get('/post/getReplyList', {
        params
    });
}

export async function replyPost(body, uuid) {
    return axios.post('/post/reply', { ...body, uuid });
}

export async function deletePostReply(body) {
    return axios.post('/post/deleteReply', body);
}

export async function queryVideoListByPage(params) {
    return axios.get('/post/pageQueryVideoList', {
        params
    });
}