<template>
  <div class="flex h-screen">
    <div v-show="isTreeVisible" class="w-64 bg-gray-100 overflow-y-auto">
      <div class="px-4 py-2 space-y-4">
        <div class="w-64 bg-gray-200 border-r border-gray-300">
          <TreeNav :treeData="treeData" @selectNode="onSelectNode" />
        </div>
      </div>
    </div>

    <div class="flex-1 p-4">
      <a href="#" @click.prevent="toggleTreeVisibility" class="text-gray-700 hover:text-gray-900 m-4">
        <span v-if="!isTreeVisible">显示导航</span>
        <span v-if="isTreeVisible">隐藏导航</span>
      </a>
      <div v-if="selectedNode" class="space-y-6 mt-5 text-left lg:max-w-4xl lg:mx-auto">
        <!-- <h2 class="text-2xl font-medium">{{ selectedNode.title }}</h2> -->
        <div v-if="!content" class="text-4xl text-red-400">加急更新中，敬请期待！</div>
        <MdPreview v-else :modelValue="content" />
      </div>
      <div v-else class="flex items-center justify-center h-full">
        <p class="text-gray-500">请选择一个节点查看内容</p>
      </div>
    </div>
  </div>
</template>

<script>
import TreeNav from '@/components/TreeNav.vue';
import { getQuestionDetail, getQuestionTree } from '@/openapi/question';
import { MdPreview } from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { ref } from 'vue';

export default {
  components: {
    TreeNav,
    MdPreview
  },
  data() {
    return {
      treeData: [],
      selectedNode: null,
      isTreeVisible: true,
      content: ref(""),
    };
  },
  methods: {
    async fetchContent(id) {
      try {
        const res = await getQuestionDetail({ id });
        this.content = res.data.data.content;
      } catch (error) {
        console.error("Error fetching content:", error);
        this.content = ""; // 清空内容，以便显示“加急更新中”
      }
    },
    async onSelectNode(node) {
      this.selectedNode = node;
      await this.fetchContent(node.id);
    },
    toggleTreeVisibility() {
      this.isTreeVisible = !this.isTreeVisible;
    }
  },
  created() {
    getQuestionTree()
      .then(response => {
        this.treeData = response.data.data;
      })
      .catch(error => console.error('Error fetching tree data:', error));
  }
};
</script>

<style scoped>
/* 添加适当的CSS样式 */
</style>
