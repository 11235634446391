<template>

    <body class="bg-100">
        <!-- Rest of your content -->
        <div class="w-screen mx-auto max-w-screen-xl px-4 py-8">
            <!-- 这里是你的内容 -->
            <p class="text-3xl font-bold">欢迎来到极海开发者社区</p>
            <div class="relative inline-block text-gray-800">
                <span
                    class="text-reveal animate-reveal mt-4 text-blue-500 text-3xl font-bold">这里提供真实顶尖的互联网技术分享，助力高端面试与职业发展</span>
            </div>
            <p class="mt-4">
            <div>
                <p>
                    已助力<span
                        class="text-reveal animate-reveal underline decoration-gray-900 font-bold text-2xl">2853</span>人拿到Offer
                </p>
                <p>
                    面试复盘与技术讲解视频累计更新<span
                        class="text-reveal animate-reveal underline decoration-gray-900 font-bold text-2xl">87</span>个
                </p>
                <p>
                    累计举办线上周会<span
                        class="text-reveal animate-reveal underline decoration-gray-900 font-bold text-2xl">34</span>场
                </p>
                <p>
                    加入社区累计<span
                        class="text-reveal animate-reveal underline decoration-gray-900 font-bold text-2xl">1452</span>人
                </p>
            </div>


            <div class="flex text-reveal animate-reveal justify-center">
                <img src="@/assets/userlist.jpg" alt="图片描述" style="width: 40%;" />
            </div>
            </p>
            <a href="register"
                class="bg-blue-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline text-2xl">
                加入社区
            </a>
            <div class="inset-0 flex justify-center">
                <img src="@/assets/home.png" alt="图片描述" class="lg:w-1/2 sm:w-full" />
            </div>

            <div class="grid grid-cols-3 gap-5">
                <div class="relative overflow-hidden w-full rounded-lg gradient-border">
                    <div class="flex">
                        <div class="w-full p-4">
                            <!-- 右侧内容 -->
                            <p class="font-bold">俊河</p>
                            <div class="text-sm">高级开发</div>
                            <p class="text-sm max-w-[50ch] mt-4 overflow-hidden text-ellipsis">
                                社区周会真的学到很多！</p>
                        </div>
                    </div>
                </div>
                <div class="relative overflow-hidden w-full rounded-lg gradient-border">
                    <div class="flex">
                        <div class="w-full p-4">
                            <!-- 右侧内容 -->
                            <p class="font-bold">TimeMoney</p>
                            <div class="text-sm">技术专家</div>
                            <p class="text-sm max-w-[50ch] mt-4 overflow-hidden text-ellipsis">
                                最喜欢场景题，解析让人醍醐灌顶！</p>
                        </div>
                    </div>

                </div>
                <div class="relative overflow-hidden w-full rounded-lg gradient-border">
                    <div class="flex">
                        <div class="w-full p-4">
                            <!-- 右侧内容 -->
                            <p class="font-bold">进击小波</p>
                            <div class="text-sm">架构师</div>
                            <p class="text-sm max-w-[50ch] mt-4 overflow-hidden text-ellipsis">
                                非常感谢海哥的技术指导，解决了一直困惑的问题</p>
                        </div>
                    </div>

                </div>
            </div>


            <div class="grid grid-cols-3 gap-5 mt-8">
                <div class="relative overflow-hidden w-full rounded-lg gradient-border">
                    <div class="flex">
                        <div class="w-full p-4">
                            <!-- 右侧内容 -->
                            <p class="font-bold">游艺</p>
                            <div class="text-sm">开发工程师</div>
                            <p class="text-sm max-w-[50ch] mt-4 overflow-hidden text-ellipsis">
                                跟着海哥学习，校招拿了5家大厂Offer！</p>
                        </div>
                    </div>
                </div>
                <div class="relative overflow-hidden w-full rounded-lg gradient-border">
                    <div class="flex">
                        <div class="w-full p-4">
                            <!-- 右侧内容 -->
                            <p class="font-bold">Venus</p>
                            <div class="text-sm">技术专家</div>
                            <p class="text-sm max-w-[50ch] mt-4 overflow-hidden text-ellipsis">
                                现已从职场过度到独立开发！海哥牛逼！</p>
                        </div>
                    </div>

                </div>
                <div class="relative overflow-hidden w-full rounded-lg gradient-border">
                    <div class="flex">
                        <div class="w-full p-4">
                            <!-- 右侧内容 -->
                            <p class="font-bold">水桥</p>
                            <div class="text-sm">高级开发</div>
                            <p class="text-sm max-w-[50ch] mt-4 overflow-hidden text-ellipsis">
                                题库的设计思路很有用！已顺利拿到Offer！</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </body>


    <footer class="bg-gray-200 py-4 w-full">
        <div class="container mx-auto flex items-center justify-center gap-4">
            <div class="text-gray-600">
                &copy; bitfree 版权所有. All rights reserved.
            </div>
            <div class="text-gray-600">
                <a href="https://beian.miit.gov.cn/" target="_blank" class="hover:text-gray-800">
                    浙ICP备2023016718号-1
                </a>
            </div>
        </div>
    </footer>

</template>

<script>
export default {
    data() {
        return {
            completedAssistants: 1032,
            activeUsers: 892,
            videoUpdates: 30,
            onlineMeetings: 7
        };
    },
    methods: {
        submitForm() {
            // 在此处处理登录逻辑，例如发送请求到服务器
            this.showModal = false;
        },
        mounted() {
        }
    }
};
</script>

<style>
.gradient-border {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s ease-out;
}

.modal-enter,
.modal-leave-to {
    opacity: 0;
}


footer {
    bottom: 0;
    /* 全宽 */
    width: 100%;
    /* 防止遮挡其他内容 */
    z-index: 100;
}

.container {
    /* 自动调整宽度并居中 */
    max-width: 1200px;
    /* 你可以根据需要调整这个值 */
    margin: 0 auto;
}

/* Flex布局确保内部元素居中 */
.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

@keyframes reveal {
    0% {
        transform: rotateY(180deg);
        opacity: 0;
    }

    100% {
        transform: rotateY(0deg);
        opacity: 1;
    }
}

.text-reveal {
    display: inline-block;
    backface-visibility: hidden;
    /* 防止背面在旋转时可见 */
    will-change: transform, opacity;
}

.animate-reveal {
    animation: reveal 1.5s ease-in-out forwards;
}
</style>
