<template>
    <div class="container mx-auto max-w-screen-xl mt-10">

        <div class="grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4">
            <div v-for="video in videos" :key="video.id"
                class="rounded-lg overflow-hidden shadow-md bg-white cursor-pointer" @click="postDetail(video.id)">
                <img :src="video.poster" alt="Video Thumbnail" class="w-full h-48 object-cover" />
                <div class="px-4 py-2">
                    <h3 class="text-gray-700 font-medium mb-1"> {{ video.title }}</h3>
                    <div class="items-center mb-2">
                        <span class="text-gray-600">{{ formatTime(video.createTime) }}</span>
                        <!-- 添加条件渲染的新标志 -->
                        <span v-if="video.newVideo"
                            class="ml-2 text-sm bg-blue-100 text-blue-800 rounded-full px-2 py-1">New</span>
                    </div>
                </div>
            </div>
        </div>
        <nav class="flex items-center justify-between mt-10">
            <button v-if="page > 1" @click="loadPage(page - 1)"
                class="px-4 py-2 rounded-md border border-gray-300 text-gray-600 hover:text-gray-800">上一页</button>
            <span>第 {{ page }} 页</span>
            <button v-if="videos.length % perPage == 0" @click="loadPage(page + 1)"
                class="px-4 py-2 rounded-md border border-gray-300 text-gray-600 hover:text-gray-800">下一页</button>
        </nav>
    </div>
</template>


<script>
import { queryVideoListByPage } from '@/openapi/post';

export default {
    data() {
        return {
            videos: [],
            page: 1, // 当前页数
            perPage: 12 // 每页显示的视频数量
        };
    },
    async created() {
        await this.fetchVideos();
    },
    computed: {
    },
    methods: {
        formatTime(timestamp) {
            const date = new Date(timestamp);
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
        },
        async fetchVideos() {
            try {
                const response = await queryVideoListByPage({ page: this.page, size: this.perPage });
                this.videos = response.data.data.list;
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        },
        loadPage(newPage) {
            this.page = newPage;
            this.fetchVideos();
        },
        postDetail(id) {
            this.$router.push({ path: 'postDetail', query: { id: id } });
        }
    },
};
</script>